import { Component, OnInit } from '@angular/core';
import {RoutesService} from "../../../core/services/routes.service";

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {

  constructor(
      public routesService: RoutesService,
  ) { }

  ngOnInit(): void {
  }

}
