<div class="content--top">
    <div class="content--top__title">
        <div class="content-container">
            <h1 i18n>All-on-4</h1>
        </div>
    </div>
    <div class="content--top__lead">
        <div class="content-container">
            <div class="content--top__lead__container">
                <div class="content--top__lead__image">
                    <img src="assets/images/treatments/top/All-on-4.jpg">
                </div>
                <div class="content--top__lead__title">
                    <h2 i18n>Enjoy life again with fix teeth!</h2>
                    <div class="content--top__lead__title__separator"></div>
                </div>
                <div class="content--top__lead__description">
                    <p i18n class="bold">All-on-4 method</p>

                    <p i18n>„Fixed teeth already with 4 implants and without bone augmentation“</p>

                    <app-top-navigation></app-top-navigation>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="content--main">
    <div class="content-container">
        <div class="content--main__information">
            <p i18n>No matter what phase of life you are in, the goal is finally being able to eat, laugh and live carefree again!</p>
        </div>
        <div class="content__accordions">
            <div class="content__accordion">
                <input type="checkbox" id="chck1">
                <label i18n class="content__accordion__label" for="chck1">
                    The All-on-4 Method in brief
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <p i18n>Fixed dentures are no longer a dream even if you have an edentulous jaw with limited bone volume. The all-on-4 treatment concept without bone augmentation and sinus lift makes fixed teeth possible for you too. With this method, a fixed denture can already be produced with 4 implants per jaw. After a healing time of 4-6 months, you will have completely new and fully usable teeth again.</p>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck2">
                <label i18n class="content__accordion__label" for="chck2">
                    The advantages of All-on-4
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Fixed, stable denture.
                        </div>
                        <div i18n class="content--main__content__list__item">Long-term solution with high resilience
                        </div>
                        <div i18n class="content--main__content__list__item">Beautiful smile, high aesthetics - indistinguishable from real teeth
                        </div>
                        <div i18n class="content--main__content__list__item">Natural mouthfeel and chewability, best wearing comfort.
                        </div>
                        <div i18n class="content--main__content__list__item">All-on-4 costs are even lower than traditional solutions.
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__accordion">
                <input type="checkbox" id="chck3">
                <label i18n class="content__accordion__label" for="chck3">
                    Who is recommended the All-on-4 method for?
                    <img src="assets/icons/accordion.svg">
                </label>
                <div class="content__accordion__content">
                    <div class="content--main__content__list">
                        <div i18n class="content--main__content__list__item">Patients who are already toothless or whose remaining teeth can no longer be preserved.
                        </div>
                        <div i18n class="content--main__content__list__item">Patients who do not want to have bone augmentation.
                        </div>
                        <div i18n class="content--main__content__list__item">Patients who do not have sufficient bone stock for a fixed full bridge.
                        </div>

                        <div i18n class="content--main__content__list__item">Patients seeking an affordable, mainstream alternative to traditional types of care.
                        </div>
                        <div i18n class="content--main__content__list__item">Patients who want a shorter healing time
                        </div>
                        <div i18n class="content--main__content__list__item">Patients who want to beautify themselves for a well-groomed, youthful, beautiful appearance.
                        </div>
                        <div i18n class="content--main__content__list__item">Patients who want to eat well and chew healthily.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-container wide">
        <p i18n>The biggest advantage of this high-quality denture is that neither you nor others can tell your new  teeth  from  your  natural  teeth.  Without  exception,  patients  who  had  a  conventional denture before an All-on-4 bridge are delighted with the result.</p>
        <ng-container *ngIf="locale === 'fr'">
        <p>Vous souhaitez profiter des avantages listés ci-dessus ?</p>
        <p>Si vous avez des questions ou souhaitez prendre rendez-vous, veuillez remplir le formulaire ci-dessous et nous vous contacterons.</p>
        </ng-container>
        <app-contact-form></app-contact-form>
    </div>
</div>

<div class="content--bottom">
    <img src="assets/images/treatments/bottom/1.jpg">
</div>

