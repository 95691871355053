import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CookieService} from "ngx-cookie-service";


declare var gtag: any;
declare var dataLayer: any;
@Component({
    selector: 'app-cookie',
    templateUrl: './cookie.component.html',
    styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
    showMore = false;
    cookieClosed = false
    @Output() showMoreEmitter = new EventEmitter<boolean>();
    @Output() showCookieEmitter = new EventEmitter<boolean>();

    prefixes = [
        'en.',
        'it.',
        'fr.'
    ]

    // set host for language prefixes
    baseUrl = document.location.host;
    host = (this.prefixes.indexOf(this.baseUrl.slice(0, 3)) < 0) ? this.baseUrl : this.baseUrl.slice(3);


    statisticChecked = false;
    marketingChecked = false;

    constructor(
        private cookieService: CookieService,
    ) {
    }

    ngOnInit(): void {
    }

    moreSettings() {
        this.showMore = true;
        this.showMoreEmitter.emit(this.showMore);
    }

    closeMore() {
        this.handleCookieClose();
    }

    acceptSelected() {
        if (this.statisticChecked) {
            gtag('consent', 'update', {
                'analytics_storage': 'granted',
            });
            this.cookieService.set('statistic_accepted', 'true', 365, '', this.host);
        }
        if (this.marketingChecked) {
            gtag('consent', 'update', {
                'ad_storage': 'granted',
            });
            this.cookieService.set('marketing_accepted', 'true', 365, '', this.host);
        }
        this.cookieService.set('accepted', 'true', 365, '', this.host);
        this.handleCookieClose();
    }

    acceptAll() {
        gtag('consent', 'update', {
            'analytics_storage': 'granted',
            'ad_storage': 'granted',
        });
        this.cookieService.set('accepted', 'true', 365, '', this.host);
        this.cookieService.set('statistic_accepted', 'true', 365, '', this.host);
        this.cookieService.set('marketing_accepted', 'true', 365, '', this.host);
        this.handleCookieClose();
    }

    handleCookieClose() {
        this.showMore = false;
        this.cookieClosed = true;
        this.showMoreEmitter.emit(this.showMore);
    }

}
