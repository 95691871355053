<div class="cookie" *ngIf="!showMore && !cookieClosed">
    <div class="cookie-container">
        <div class="cookie__text-container">
            <div i18n class="cookie__title">A cookie-k használata</div>
            <div i18n class="cookie__text">Unsere Website verwendet Cookies, um Ihnen die verfügbaren Dienste und
                Einstellungen
                zur Verfügung zu stellen und um Ihren Besuch auf unserer Website zu überwachen.
            </div>
        </div>
        <div i18n class="cookie__settings" (click)="moreSettings()">Cookies einstellen</div>
        <div i18n class="cookie__accept" (click)="acceptAll()">Alle cookies akzeptieren</div>
    </div>
</div>

<div class="cookie--more" *ngIf="showMore">
    <div i18n class="cookie--more__title">Der Schutz Ihrer Daten ist für uns wichtig
    </div>
    <div class="cookie--more__close">
        <img src="assets/icons/cookie-close.svg" (click)="closeMore()">
    </div>
    <div class="cookie--more__text">
        <p i18n>Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, soziale Funktionen bereitzustellen
            und den
            Verkehr auf unserer Website zu analysieren. Darüber hinaus geben wir Ihre Website-Nutzungsdaten an unsere
            Partner für soziale Medien, Werbung und Analysen weiter, die diese Daten möglicherweise mit anderen Daten
            kombinieren, die Sie ihnen zur Verfügung gestellt haben oder die von anderen von Ihnen genutzten Diensten
            erfasst wurden.</p>
        <p i18n>Mit Ihrer Zustimmung können wir und unsere Partner auch genaue Geolokalisierungsdaten und
            Identifizierungsinformationen verwenden, die durch die Verfolgung von Vermögenswerten gewonnen wurden. Indem
            Sie auf die entsprechende Stelle klicken, stimmen Sie der oben beschriebenen Verarbeitung Ihrer Daten durch
            uns und unsere Partner zu. Sie können aber auch auf die entsprechende Stelle klicken, um sich abzumelden
            oder ausführlichere Informationen zu erhalten und Ihre Präferenzen zu ändern, bevor Sie Ihre Zustimmung
            geben.</p>
        <p i18n>Bitte beachten Sie, dass einige Verarbeitungen Ihrer personenbezogenen Daten nicht notwendigerweise Ihre
            Zustimmung erfordern, aber Sie haben das Recht, einer solchen Verarbeitung zu widersprechen. Ihre
            Einstellungen sind nur für diese Website gültig. Sie können Ihre Präferenzen jederzeit ändern, indem Sie zu
            dieser Website zurückkehren oder den Datenschutzhinweis lesen.</p>
        <p i18n>Weitere Informationen über Cookies finden Sie in unserem <span
                class="bold">Datenschutzrichtlinien.</span>
        </p>
    </div>

    <div class="cookie--more__control">
        <div i18n class="only-selected" (click)="acceptSelected()">Auswahl akzeptieren</div>
        <div i18n class="all" (click)="acceptAll()">Alle cookies akzeptieren</div>
    </div>

    <div class="cookie--more__cookies">
        <div i18n class="text">Benötigte Cookies</div>
        <label class="checkbox statistic">
            <div i18n>Statistic-Cookies</div>
            <input type="checkbox" name="statistic" id="statistic" [(ngModel)]="statisticChecked">
            <span class="checkmark"></span>
        </label>
        <label class="checkbox marketing">
            <div i18n>Marketing-Cookies</div>
            <input type="checkbox" name="marketing" id="marketing" [(ngModel)]="marketingChecked">
            <span class="checkmark"></span>
        </label>
    </div>
</div>
