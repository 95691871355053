<app-actual-informations (setInformationDisplay)="onInformationDisplay($event)"></app-actual-informations>
<div class="navbar-contact" [ngClass]="[isPrimary ? 'primary' : 'secondary']">
    <a i18n href="tel:00 36 96 578 250">
        <img src="assets/icons/navbar/phone.svg">hívjon minket</a>
    <a i18n routerLink="{{routesService.getRouterLink('contact')}}" fragment="webform">
        <img src="assets/icons/navbar/envelope.svg">kapcsolat</a>
</div>
<div class="navbar" [ngClass]="[hasBackground ? 'has-background' : '', isPrimary ? 'primary' : 'secondary']">
    <div class="navbar-container">
        <div class="navbar__main">
            <a class="navbar__main__logo de" routerLink="" *ngIf="locale === 'de'">
                <img class="primary" src="assets/eurodent-logo.svg">
                <img class="secondary" src="assets/eurodent-logo-light.svg">
            </a>
            <a class="navbar__main__logo en" routerLink="" *ngIf="locale === 'en'">
                <img class="primary" src="assets/eurodent-logo-en.png">
                <img class="secondary" src="assets/eurodent-logo-light-en.png">
            </a>
            <a class="navbar__main__logo fr" routerLink="" *ngIf="locale === 'fr'">
                <img class="primary" src="assets/eurodent-logo-fr.png">
                <img class="secondary" src="assets/eurodent-logo-light-fr.png">
            </a>
            <a class="navbar__main__logo it" routerLink="" *ngIf="locale === 'it'">
                <img class="primary" src="assets/eurodent-logo-it.png">
                <img class="secondary" src="assets/eurodent-logo-light-it.png">
            </a>
            <div class="navbar__main__language" *ngIf="hamburgerToggle" [ngClass]="languageToggle ? 'opened' : ''">
                <div class="navbar__main__language__control" (click)="onToggleLanguage()">
                    <span>{{langService.getLang()}}</span>
                    <img class="arrow" src="assets/icons/triangle-big-down-orange.svg">
                </div>
                <div class="navbar__main__language__options">
                    <div class="navbar__main__language__options__item" *ngFor="let lang of languages">
                        <ng-container *ngIf="lang === 'de'; else otherLang">
                            <a
                                    (click)="onChangeLang()"
                                    [ngStyle]="langService.getLang() === lang ? {'display': 'none'} : {'display':'block'}"
                                    href="https://{{host}}"><span>{{lang}}</span></a>
                        </ng-container>

                        <ng-template #otherLang>
                            <a
                                    (click)="onChangeLang()"
                                    [ngStyle]="langService.getLang() === lang ? {'display': 'none'} : {'display':'block'}"
                                    href="https://{{lang}}.{{host}}"><span>{{lang}}</span></a>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="navbar__main__toggler" (click)="onToggleHamburger()">
                <div class="primary">
                    <img src="assets/icons/menu_new.svg" *ngIf="!hamburgerToggle">
                    <img src="assets/icons/menu-close_new.svg" *ngIf="hamburgerToggle">
                </div>
                <div class="secondary">
                    <img src="assets/icons/menu-white_new.svg" *ngIf="!hamburgerToggle">
                    <img src="assets/icons/menu-close-white_new.svg" *ngIf="hamburgerToggle">
                </div>
            </div>
        </div>
        <div class="navbar__collapse" *ngIf="hamburgerToggle">

            <div class="navbar__collapse__item logo de" *ngIf="locale === 'de'">
                <a routerLink="">
                    <img class="primary" src="assets/eurodent-logo.svg">
                    <img class="secondary" src="assets/eurodent-logo-light.svg">
                </a>
            </div>
            <div class="navbar__collapse__item logo en" *ngIf="locale === 'en'">
                <a routerLink="">
                    <img class="primary" src="assets/eurodent-logo-en.png">
                    <img class="secondary" src="assets/eurodent-logo-light-en.png">
                </a>
            </div>
            <div class="navbar__collapse__item logo fr" *ngIf="locale === 'fr'">
                <a routerLink="">
                    <img class="primary" src="assets/eurodent-logo-fr.png">
                    <img class="secondary" src="assets/eurodent-logo-light-fr.png">
                </a>
            </div>
            <div class="navbar__collapse__item logo it" *ngIf="locale === 'it'">
                <a routerLink="">
                    <img class="primary" src="assets/eurodent-logo-it.png">
                    <img class="secondary" src="assets/eurodent-logo-light-it.png">
                </a>
            </div>
            <div class="navbar__collapse__item offers">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('offers')">Angebote</div>
                <div class="navbar__collapse__item__submenu" id="offers" [ngClass]="openedSubmenu === 'offers' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('offers')">Angebote</div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('welcome-package')}}">Begrussungspaket
                            für 120 €</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item" *ngFor="let offer of offers">
                        <a class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('offer')}}/{{offer.slug}}">
                            {{offer.title}}
                        </a>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item services">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('services')">Leistungen</div>
                <div class="navbar__collapse__item__submenu" id="services"
                     [ngClass]="openedSubmenu === 'services' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('services')">Leistungen
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title"
                           routerLink="{{routesService.getRouterLink('free-pick-up')}}">Kostenloser Abholdienst</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title"
                           routerLink="{{routesService.getRouterLink('free-consultation')}}">Kostenlose Erstuntersuchung</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('free-accommodation')}}">Kostenlose
                            Unterkunft</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('weekend-treatment')}}">Wochenendbehandlung</a>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item treatments">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('treatments')">Behandlungen
                </div>
                <div class="navbar__collapse__item__submenu" id="treatments"
                     [ngClass]="openedSubmenu === 'treatments' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('treatments')">Behandlungen
                    </div>
                    <div class="navbar__collapse__item__submenu__item column" [ngClass]="implantToggle ? 'opened' : ''"
                         (click)="onToggleImplant()">
                        <div i18n class="navbar__collapse__item__submenu__item__title more">Zahnimplantat</div>
                        <div class="navbar__collapse__item__submenu__item__sub">
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('dental-implants')}}">Zahnimplantate</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('overdenture')}}">Stegprothese</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('fixed-denture')}}">Feste
                                Zähne</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('all-on-4')}}">All-on-4</a>
                        </div>
                    </div>
                    <div class="navbar__collapse__item__submenu__item column"
                         [ngClass]="orthodonticsToggle ? 'opened' : ''"
                         (click)="onToggleOrthodontics()">
                        <div i18n class="navbar__collapse__item__submenu__item__title more">Zahnregulierung</div>
                        <div class="navbar__collapse__item__submenu__item__sub">
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('removable-braces')}}">Herausnehmbare Zahnspange
                            </a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('fixed-braces')}}">Feste Zahnspange</a>
                        </div>
                    </div>
                    <div class="navbar__collapse__item__submenu__item column"
                         [ngClass]=" denturesToggle ? 'opened' : ''"
                         (click)="OnToggleDentures()">
                        <div i18n class="navbar__collapse__item__submenu__item__title more">Zahnersatz / Zahnästhetik
                        </div>
                        <div class="navbar__collapse__item__submenu__item__sub">
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('dental-crown')}}">Zahnkrone</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('dental-bridge')}}">Zahnbrücke</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('full-denture')}}">Zahnprothese</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('combined-dentures')}}">Kombinierter Zahnersatz</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('inlay-onlay-filling')}}">Inlay / Onlay / Füllung</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('veneer')}}">Veneer
                                / Verblendschale</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('cad-cam-technologies')}}">CAD/CAM Technologie</a>
                        </div>
                    </div>
                    <div class="navbar__collapse__item__submenu__item column"
                         [ngClass]=" prophylaxisToggle ? 'opened' : ''"
                         (click)="onToggleProphylaxis()">
                        <div i18n class="navbar__collapse__item__submenu__item__title more">Prophylaxe</div>
                        <div class="navbar__collapse__item__submenu__item__sub">
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('ems-dental-spa')}}">EMS DENTAL Spa</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item" routerLink="{{routesService.getRouterLink('oral-hygiene')}}">Mundhygiene</a>
                        </div>
                    </div>
                    <div class="navbar__collapse__item__submenu__item column"
                         [ngClass]=" diagnosisToggle ? 'opened' : ''"
                         (click)="onToggleDiagnosis()">
                        <div i18n class="navbar__collapse__item__submenu__item__title more">Diagnostik /
                            Angstpatienten
                        </div>
                        <div class="navbar__collapse__item__submenu__item__sub">
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('sedation')}}">Sedierung</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('3D-volume-tomography')}}">3D Volumentomographie</a>
                            <a i18n class="navbar__collapse__item__submenu__item__sub__item"
                               routerLink="{{routesService.getRouterLink('panoramic-x-ray')}}">Panoramaröntgenaufnahme</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item prices">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('prices')">preise</div>
                <div class="navbar__collapse__item__submenu" id="prices" [ngClass]="openedSubmenu === 'prices' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('prices')">preise
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('costs')}}">Preisbeispiele</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('health-insurance')}}">Krankenversicherung</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('warranty')}}">Garantie</a>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item about-us">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('about-us')">über uns</div>
                <div class="navbar__collapse__item__submenu" id="about-us"
                     [ngClass]="openedSubmenu === 'about-us' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('about-us')">über uns</div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('your-benefits')}}">Ihre
                            Vorteile</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('our-clinic')}}">Unsere
                            Klinik</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('our-team')}}">Unser
                            Team</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('gallery')}}">Galerie</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title"
                           routerLink="{{routesService.getRouterLink('faq')}}">Häufig gestellte Fragen</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title"
                           routerLink="{{routesService.getRouterLink('eurodent-dental-laboratory')}}">Eurodent Zahntechniklabor</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('blog')}}">Blog</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('list-of-treatments')}}">Liste der Behandlungen</a>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item contact">
                <div i18n class="navbar__collapse__item__title" (click)="openSubmenu('contact')">kontakt</div>
                <div class="navbar__collapse__item__submenu" id="contact" [ngClass]="openedSubmenu === 'contact' ? 'submenuOpen':''">
                    <div i18n class="navbar__collapse__item__submenu__title" (click)="closeSubmenu('contact')">kontakt</div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('contact')}}">Kontakt</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title" routerLink="{{routesService.getRouterLink('travel-information')}}">Anreise</a>
                    </div>
                    <div class="navbar__collapse__item__submenu__item">
                        <a i18n class="navbar__collapse__item__submenu__item__title"
                           routerLink="{{routesService.getRouterLink('corona-virus-and-current-travel-infos')}}">Coronavirus und Reiseinfos</a>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item lang">
                <div class="navbar__main__language" [ngClass]="languageToggle ? 'opened' : ''">
                    <div class="navbar__main__language__control" (click)="onToggleLanguage()">
                        <span>{{langService.getLang()}}</span>
                        <img class="arrow" src="assets/icons/triangle-big-down-orange.svg">
                    </div>
                    <div class="navbar__main__language__options">
                        <div class="navbar__main__language__options__item" *ngFor="let lang of languages">
                            <ng-container *ngIf="lang === 'de'; else otherLang">
                                <a
                                        (click)="onChangeLang()"
                                        [ngStyle]="langService.getLang() === lang ? {'display': 'none'} : {'display':'block'}"
                                        href="https://{{host}}"><span>{{lang}}</span></a>
                            </ng-container>

                            <ng-template #otherLang>
                                <a
                                        (click)="onChangeLang()"
                                        [ngStyle]="langService.getLang() === lang ? {'display': 'none'} : {'display':'block'}"
                                        href="https://{{lang}}.{{host}}"><span>{{lang}}</span></a>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="navbar__collapse__item information">
                <div class="information__item">
                    <div class="information__item__icon">
                        <img src="assets/icons/information/envelop.svg">
                    </div>
                    <div class="information__item__title">E-mail</div>
                    <a class="information__item__text" href="mailto:eurodent@eurodent.hu">eurodent@eurodent.hu</a>
                </div>
                <div class="information__item">
                    <div class="information__item__icon">
                        <img src="assets/icons/information/phone.svg">
                    </div>
                    <div i18n class="information__item__title">Nulltarif</div>
                    <a class="information__item__text" href="tel:0080010007000">00 800 1000 7000</a>
                </div>
            </div>
        </div>
    </div>
</div>
